import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import style from './style.module.scss'
import logo from '../../images/logo-m4.svg'
import PaperPlane from '../PaperPlane'

const SuccessfulSubmitMessage = props => {
  const { primaryColor, title, descriptionText } = props

  return (
    <div className={style.successfulSubmitMessageContainer}>
      <Link to="/" className={style.logom4}>
        <img src={logo} alt="Logo Studio M4" />
      </Link>

      <div className={style.messageBox}>
        <div
          style={{ '--plane-color': primaryColor }}
          className={style.planeImage}
        >
          <PaperPlane />
        </div>
        <div className={style.title}>{title}</div>
        <div className={style.descriptionText}>
          <p>{descriptionText}</p>
        </div>

        <div>
          <button
            type="button"
            style={{ '--button-color': primaryColor }}
            className={style.button}
            onClick={() => {
              window.location = '/'
            }}
          >
            VOLTAR PARA HOME
          </button>
        </div>
      </div>
    </div>
  )
}

SuccessfulSubmitMessage.propTypes = {
  primaryColor: PropTypes.string,
  title: PropTypes.string,
  descriptionText: PropTypes.element
}

SuccessfulSubmitMessage.defaultProps = {
  primaryColor: '',
  title: '',
  descriptionText: null
}

export default SuccessfulSubmitMessage
