import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import SuccessfulSubmitMessage from '../components/SuccessfulSubmitMessage'

import '../styles/global.scss'

const messageAttributes = {
  primaryColor: '#ffbf68',
  title: 'Seu formulário foi enviado com sucesso!',
  text: (
    <>
      Entraremos em contato em breve para marcar um horário com você. Enquanto
      isso, que tal dar mais uma olhadinha nos nossos projetos?
    </>
  )
}

const SucessfulConsultingContact = () => (
  <Layout>
    <SEO
      title="Contato para consultoria"
      keywords={[`gatsby`, `application`, `react`]}
    />
    <SuccessfulSubmitMessage
      primaryColor={messageAttributes.primaryColor}
      title={messageAttributes.title}
      descriptionText={messageAttributes.text}
    />
  </Layout>
)

export default SucessfulConsultingContact
