import React from 'react'

const PaperPlane = () => (
  <svg
    width="44px"
    height="40px"
    viewBox="0 0 44 40"
    version="1.1"
  >
    <title>Path</title>
    <desc>Created with Sketch.</desc>
    <g id="Site" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="1440_Sucesso_Consultoria" transform="translate(-250.000000, -258.000000)" fill="#000000" fillRule="nonzero">
            <path d="M293.615798,258.239015 C293.599197,258.222541 293.581153,258.207696 293.561845,258.194752 C293.231723,257.973259 292.82383,257.94013 292.493437,258.098262 L250.604216,277.24367 C250.598983,277.245933 250.593931,277.248558 250.588878,277.251093 C250.214638,277.444164 250,277.75364 250,278.100227 C250,278.549551 250.254426,278.920669 250.636155,279.033543 C251.367223,279.336592 252.470366,279.830359 253.745743,280.401156 C258.492949,282.525939 262.524245,284.284674 263.774991,284.284674 C263.964999,284.284674 264.112511,284.246567 264.226101,284.168179 C264.235123,284.161934 264.243784,284.155236 264.252265,284.148266 L288.00616,264.322174 L265.898364,286.696666 C265.722702,286.867561 265.660178,287.188895 265.660178,287.414552 C265.660178,287.419802 265.660268,287.424962 265.660539,287.430212 L266.075469,297.057 C266.07971,297.489849 266.393953,297.890022 266.809786,297.990043 C266.837393,297.996651 266.865633,298 266.893963,298 L267.091458,298 C267.394784,298 267.67763,297.890837 267.867277,297.700481 C267.894975,297.672692 267.917981,297.64065 267.935304,297.605439 L272.526246,288.327773 L281.462816,294.200843 C281.564767,294.269002 281.723558,294.359609 281.924482,294.359609 C282.099783,294.359609 282.242875,294.290545 282.344104,294.227726 C282.630107,294.122094 282.829498,293.912096 282.922156,293.618641 L293.891967,259.360876 C293.905861,259.328833 293.915335,259.294527 293.919575,259.258411 C293.996625,258.879238 293.887095,258.50975 293.615798,258.239015 Z" id="Path"></path>
        </g>
    </g>
  </svg>
)

export default PaperPlane
